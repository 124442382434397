<template>
  <div class="product-container-image text-no-select">
    <!-- <div class="image-preloads">
      <img
        v-for="(image, $imageIndex) in endImages"
        :key="'preload' + $imageIndex"
        :src="image.image_url"
      />
    </div> -->
    <!-- <div
      class="fixed-image cursor"
      :class="{'after-load': !invisible, 'single-image': images.length === 1}"
    >
      <img @click="setFullscreen(true)" v-if="images && images[focusedImageIndex]" :src="images[focusedImageIndex].image_url" @load="initialImageLoaded" @error="initialImageLoaded" />
    </div> -->
    <div
      class="swiper-layout-wrapper"
      ref="layoutWrapper"
      :class="{fullscreen}"
      :style="fullscreen && dragOffset ? { top: dragOffset + 'px', opacity: dragOpacity } : {}"
    >
      <div
        v-if="fullscreen"
        class="fullscreen-icon"
      >
        <img
          class="close"
          src="/images/icons/close.svg"
          @click="setFullscreen(false)"
          @touchend.stop.prevent="setFullscreen(false)"
        />
      </div>
      <div
        ref="swiper"
        class="swiper-container"
      >
        <div
          class="swiper-wrapper"
          :key="forceRenderKey"
          @click="setFullscreen(true)"
        >
          <div
            v-for="(image, $imageIndex) in images"
            :key="$imageIndex"
            :data-variantid="image.variantid"
            class="swiper-slide cursor"
          >
            <div
              class="swiper-lazy"
              :data-background="image.image_url"
              :style="images.length === 1 ? {'background-image': `url('${image.image_url}')`} : {}"
            />
            <div
              v-if="image.large_url"
              class="swiper-slide-highres"
              :style="{'background-image': `url('${image.large_url}')`}"
            />
            <div
              v-if="images.length > 1"
              class="swiper-lazy-preloader"
            />
          </div>
        </div>
        <div
          ref="pagination"
          class="swiper-pagination"
        />
        <div
          ref="next"
          class="swiper-button-next swiper-button-black"
          v-if="swiper && images.length > 1"
          @click="swiper.slideNext()"
          @touchend.stop.prevent="swiper.slideNext()"
        ></div>
        <div
          ref="prev"
          class="swiper-button-prev swiper-button-black"
          v-if="swiper && images.length > 1"
          @click="swiper.slidePrev()"
          @touchend.stop.prevent="swiper.slidePrev()"
        ></div>
        <div
          v-if="!fullscreen"
          class="expand-icon"
          @click="setFullscreen(true)"
          @touchend.stop.prevent="setFullscreen(true)"
        >
          <img
            class="expand"
            src="/images/icons/expand.png"
            alt="Zoom Images"
          />
        </div>
      </div>
      <!-- <div
        class="swiper-thumbs"
        :class="{ 'swiper-thumbs-5': images.length % 5 === 0, 'flex-halign-center': images.length <= 5 }"
        v-show="images.length > 1"
      >
        <div
          v-for="(image, $index) in images"
          :key="$index"
          class="cursor"
          :style="{'background-image': `url('${image.thumbnail_url}')`}"
          @click="swiper ? swiper.slideTo($index + 1) : null"
          @touchend.stop.prevent="swiper ? swiper.slideTo($index + 1) : null"
          :class="{ active: focusedImageIndex === $index }"
        />
      </div> -->
    </div>
    <div
      v-if="fullscreen && !dragOffset"
      @click="setFullscreen(false)"
      class="fullscreen-close flex flex-halign-center cursor blue"
    >
      <span>Close</span>
    </div>
  </div>
</template>

<style lang="scss">
.product-container-image {
  height: 420px;
  margin-top: 15px;

  .swiper-layout-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    &.fullscreen {
      width: 100%;
      z-index: 1000;
      position: fixed;
      top: 0;
      left: 0;
      background-color: #fff;
      padding: 15px;
      height: calc(100% - 36px); // For close button.  Swiper complained when I tried to put it in the flexbox
      .fullscreen-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 2;
        cursor: pointer;
        img {
          width: 25px;
          height: 25px;
        }
      }
      .swiper-thumbs {
        display: flex;
      }
      .swiper-slide-active {
        .swiper-slide-highres {
          display: block;
        }
      }
    }
  }
  .swiper-container {
    width: 100%;
    flex-grow: 100;
    display: flex;
    .swiper-wrapper {
      height: auto;
    }
  }
  .swiper-button-next, .swiper-button-prev {
    opacity: 0.2;
  }
  .expand {
    position: absolute;
    right: 10px;
    bottom: 10px;
    cursor: pointer;
    z-index: 10;
  }
  .swiper-slide {
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .swiper-slide-highres, .swiper-lazy {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    .swiper-slide-highres {
      display: none;
    }
    .swiper-lazy-preloader {
      width: 30px;
      height: 30px;
    }
  }
  .swiper-thumbs {
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0 auto;
    max-width: 800px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 15px;
    > div {
      height: 50px;
      width: 25%;
      padding: 0.5em;
      cursor: pointer;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0.4;
      margin-top: 15px;
      &.active {
        opacity: 1;
      }
    }
    &.swiper-thumbs-5 {
      > div {
        width: 20%;
      }
    }
  }
  .swiper-pagination {
    bottom: 0 !important;
    .swiper-pagination-bullet-active {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
  .fullscreen-close {
    margin-top: 0.5em;
    height: 36px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 99;
  }
}

@media all and (max-width: 800px) {
  .product-container-image {
    height: 300px;
    .swiper-thumbs {
      display: none;
    }
  }
}
@media all and (min-width: 800px) {
  .product-container-image {
    .swiper-layout-wrapper {
      &.fullscreen {
        .swiper-thumbs {
          > div {
            width: auto;
            flex: 1;
          }
        }
      }
    }
  }
}
</style>

<script>
import Swiper, { Lazy, Pagination } from 'swiper'
import jQuery from 'jquery'
import { uniqBy } from 'lodash'

Swiper.use([Lazy, Pagination])

let touchStartY

export default {
  data () {
    return {
      fullscreen: false,
      swiper: null,
      focusedImageIndex: 0,
      variantId: 0,
      forceRenderKey: 0,
      dragOffset: 0
    }
  },
  computed: {
    product () {
      return window.productData || {}
    },
    allImages () {
      // Images are either for the specific option id or option id of 0
      const images = ((this.product && this.product.images) || [])

      // image object will have image_url, image2_url, image3_url, etc.
      // This will wlatten them all into an array of objects with image_url and variantid
      return images
        .map(image => {
          return Object.entries(image || {})
            .filter(([key, value]) => {
              return key.startsWith('image') && key.endsWith('_url') && !!value
            })
            .map(([key, value]) => {
              return {
                variantid: image.variantid,
                image_url: value,
                thumbnail_url: value.replace('/600/', '/125/'),
                large_url: value.replace('/600/', '/1300/')
              }
            })
        })
        .reduce((images, image) => images.concat(image), [])
    },
    images () {
      // let images = this.allImages.filter(image => {
      //   // Always show variantid of 0
      //   if (!this.variantId || !(this.variantId * 1) || !image.variantid || !(image.variantid * 1)) {
      //     return true
      //   }
      //   if (this.variantId && this.variantId * 1 && image.variantid + '' === this.variantId + '') {
      //     return true
      //   } else if (!(this.variantId && this.variantId * 1) && (!image.variantid || !(image.variantid * 1))) {
      //     return true
      //   }
      // })

      // if (!images.length) {
      //   images = this.allImages.filter(image => {
      //     if (!image.variantid || !(image.variantid * 1)) {
      //       return true
      //     }
      //   })
      // }
      let images = this.allImages
      images = uniqBy(images, 'image_url')
      if (this.variantId * 1) {
        images.sort((a, b) => (b.variantid || 0) - (a.variantid || 0))
      }

      // If there are no images then always return product.image_url if it exists
      if (!images.length) {
        if (this.product.image_url) {
          return [{ image_url: this.product.image_url }]
        } else {
          return []
        }
      }

      return images
    },
    swiperOptions () {
      const vm = this
      return {
        spaceBetween: 10,
        slidesPerView: 1,
        loop: true,
        preloadImages: false,
        pagination: {
          el: this.$refs.pagination,
          clickable: true
        },
        lazy: {
          loadPrevNext: true,
          loadPrevNextAmount: 3,
          loadOnTransitionStart: true
        },
        on: {
          slideChange: () => {
            vm.focusedImageIndex = vm.swiper ? vm.swiper.realIndex : 0
          }
        }
      }
    },
    endImages () {
      const images = []
      if (this.images && this.images[1]) {
        images.push(this.images[1])
      }
      if (this.images.length > 2) {
        images.push(this.images[this.images.length - 1])
      }
      return images
    },
    dragOpacity () {
      return 1 - (Math.min(this.dragOffset, 50) / 100)
    },
    dragShouldClose () {
      const dragThreshold = 50
      return this.dragOffset > dragThreshold
    }
  },
  watch: {
    async images (images) {
      await this.destroySwiper()
      if (images && images.length > 1) {
        this.initializeSwiper()
      }
    }
  },
  created () {
    // if (jQuery('#color').val()) {
    //   this.variantId = jQuery('#color').val()
    // }
  },
  async mounted () {
    window.tn = this

    this.keyupHandler = (evt) => {
      if (!this.fullscreen) {
        return
      }
      if (evt.keyCode === 27) {
        this.setFullscreen(false)
      } else if (this.swiper && evt.keyCode === 37) {
        this.swiper.slidePrev()
      } else if (this.swiper && evt.keyCode === 39) {
        this.swiper.slideNext()
      }
    }
    document.addEventListener('keyup', this.keyupHandler)

    window.filterProductImages = ({ variantid } = {}) => {
      variantid = variantid || 0
      // eslint-disable-next-line no-console
      // console.log('filter images', variantid)

      // if ((this.variantId || '') + '' !== (variantid || '') + '') {
      //   this.variantId = variantid
      // }
      const image = this.allImages.find(i => (i.variantid || '0') + '' === variantid + '')
      if (image) {
        const imageByFilename = this.images.find(i => i.image_url === image.image_url)
        const index = this.images.indexOf(imageByFilename)
        if (this.swiper) {
          this.swiper.slideTo(index + 1)
        }
      }
    }

    window.slideToThumbnail = (index, duration = 0) => {
      if (this.swiper) {
        this.swiper.slideTo(index + 1, duration)
      }
    }

    // Don't initialize swiper if there is only one image
    if (this.images.length > 1) {
      this.initializeSwiper()
    }

    // Add touch listener to layout wrapper
    if (this.$refs.layoutWrapper) {
      this.$refs.layoutWrapper.addEventListener('touchstart', e => {
        touchStartY = e.changedTouches[0].screenY
      }, { passive: true })
      this.$refs.layoutWrapper.addEventListener('touchmove', e => {
        if (this.fullscreen) {
          const dy = e.changedTouches[0].screenY - touchStartY
          if (dy > 20) {
            this.dragOffset = dy - 20
          } else {
            this.dragOffset = 0
          }
        }
      }, { passive: true })
      this.$refs.layoutWrapper.addEventListener('touchend', async e => {
        this.dragOffset = e.changedTouches[0].screenY - touchStartY - 20
        await this.$nextTick()
        if (this.fullscreen && this.dragShouldClose) {
          this.setFullscreen(false)
        }
        this.dragOffset = 0
      }, { passive: true })
    }
  },
  beforeDestroy () {
    document.removeEventListener('keyup', this.keyupHandler)
    if (jQuery('body.no-scroll').length || jQuery('html.no-scroll').length) {
      const top = jQuery('body').css('top')
      jQuery('body, html').removeClass('no-scroll')
      jQuery('body').css('top', '')
      if (top !== 'auto') {
        const scrollTop = ((top || '').replace('px', '') || 0) * -1
        document.scrollingElement.scrollTop = scrollTop
      }
    }
    window.slideToThumbnail = undefined
    this.destroySwiper()
  },
  methods: {
    async setFullscreen (value) {
      this.fullscreen = value
      if (value) {
        const scrollTop = document.scrollingElement.scrollTop
        jQuery('body, html').addClass('no-scroll')
        jQuery('body').css('top', (-1 * scrollTop) + 'px')
      } else {
        const top = jQuery('body').css('top')
        jQuery('body, html').removeClass('no-scroll')
        jQuery('body').css('top', '')
        if (top !== 'auto') {
          const scrollTop = ((top || '').replace('px', '') || 0) * -1
          document.scrollingElement.scrollTop = scrollTop
        }
      }
      await this.$nextTick()
      if (this.swiper) {
        this.swiper.update()
      }
    },
    async destroySwiper () {
      if (this.swiper) {
        this.swiper.destroy()
        this.forceRenderKey++ // Force all slide html that swiper hijacked to re-render
        this.swiper = null
      }
      this.focusedImageIndex = 0
    },
    initializeSwiper () {
      if (this.images.length > 1) {
        this.swiper = new Swiper(this.$refs.swiper, this.swiperOptions)
      }
    },
    goNext () {
      // eslint-disable-next-line no-console
      // console.log('go next')
      this.swiper.slideNext()
    }
  }
}
</script>
