<template>
  <transition
    name="menu-fade"
    @after-enter="menuSlideIn = true"
    @before-leave="menuSlideIn = false"
  >
    <div
      v-if="cartMenuOpen"
      class="cart-menu-wrapper"
    >
      <v-style>body { overflow: hidden }</v-style>
      <transition
        name="menu-slide-left"
      >
        <div
          class="cart-menu-container"
          @click.stop.prevent
          v-if="menuSlideIn"
        >
          <header>
            <button
              class="close"
              @click="cartMenu(false)"
            ><img src="/images/icons/close.svg" /></button>
            {{ itemCount || '0' }} Item{{ (itemCount || 0) * 1 === 1 ? '' : 's' }}
          </header>
          <div
            class="cart-menu"
            :class="{ loading: cartLoading }"
          >
            <div v-if="itemCount">
              <div class="contentGroupContainer padding-bottom-m">
                <div id="cartDisplayContainer">
                  <div id="cartDisplayContainer-scroll">
                    <table class="products-cart-t" :data-cart-items="itemCount">
                      <tbody>
                        <template
                          v-for="(product, $index) in products"
                        >
                          <tr
                            :key="'item' + $index"
                          >
                            <td :id="`img_${product.cartid || $index}`">
                              <div class="checkoutImageBox center">
                                <img
                                  :src="product.image_url"
                                  :alt="product.product"
                                  :title="product.product"
                                  @click="product.url ? $router.push({ path: '/' + product.url }) : () => {}"
                                  class="cursor"
                                >
                              </div>
                            </td>
                            <td class="products-cart-details" :id="`product_${product.cartid}`">
                              <div class="checkoutProductTitle text-bold" :id="`optionName-${product.cartid}`">
                                {{ product.product }}
                              </div>
                              <div
                                v-if="product.preorder_date"
                                class="preorderText red"
                              ><strong>PRE-ORDER:</strong> Ships {{ product.preorder_date }}</div>
                              <div class="poptions-options-list text-grey">
                                {{ product.productcode }}
                              </div>
                              <div>
                                <div
                                  v-if="product.options"
                                  class="poptions-options-list">
                                  <span
                                    class="poptions-item"
                                  >{{ product.options }}</span>
                                </div>
                              </div>
                              <span
                                v-if="!!product.is_coupon"
                                class="currency text-grey"
                              >FREE!</span>
                              <template
                                v-else
                              >
                                <span class="currency">{{ product.price_unit }}</span> x {{ product.qty }} =
                                <span class="text-bold"><span class="currency">{{ product.price_total }}</span></span>
                                <span class="MarketPrice"></span>
                              </template>
                            </td>
                            <td class="cart-qty" style="padding-right:10px;">
                              <span class="product-quantity-selector">
                                <span class="quantity-selector-wrapper">
                                  <span data-dir="d" class="quantity-selector-btn qs-decrease">-</span>
                                  <input type="number" class="quantity-selector-input" :id="`amount_${product.cartid}`" :value="product.qty" :onchange="`checkoutUpdateOptions(${product.cartid});`">
                                  <span data-dir="i" class="quantity-selector-btn qs-increase">+</span>
                                </span>
                              </span>
                              <div class="removeCartItem grey" :onclick="`$('#amount_${product.cartid}').val(0); checkoutUpdateOptions(${product.cartid});`">Remove Item</div>
                            </td>
                            <td>
                              <span
                                v-if="!product.is_coupon"
                                class="red text-bold cursor padding-s"
                                @click="deleteItem(product.is_giftcert ? 'giftcert' : 'product', product)"
                              >X</span>
                            </td>
                          </tr>
                          <tr
                            :key="'divider-' + $index"
                          >
                            <td colspan="4">
                              <div class="divider"></div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                    <br>
                  </div>
                </div>
              </div>
              <div
                class="checkout-button margin-top-m margin-bottom-m"
                style="display: none;"
              >
                <a
                  href="/cart.php"
                  class="button button-large blue button-white"
                  :class="{ 'grey-bg' : loadingCheckout }"
                  @click="checkout"
                >{{ loadingCheckout ? 'Loading...' : 'Checkout' }}</a>
              </div>

              <table class="checkout-totals-table">
                <tbody>
                  <tr>
                    <td class="td-nowrap">Items ({{ itemCount }}):</td>
                    <td class="td-nowrap td-align-r"><span><span class="currency">{{ cart.subtotal }}</span></span></td>
                    <td class="td-nowrap td-align-r"></td>
                  </tr>
                  <tr
                    v-if="cart.display_discounted_subtotal"
                  >
                    <td class="td-nowrap">Discounted Subtotal:</td>
                    <td class="td-nowrap td-align-r"><span><span class="currency">{{ cart.display_discounted_subtotal }}</span></span></td>
                    <td class="td-nowrap td-align-r"></td>
                  </tr>
                  <tr
                    v-for="(taxItem, $taxItemIndex) in (cart.taxes || [])"
                    :key="$taxItemIndex"
                  >
                    <td class="td-nowrap">{{ taxItem.tax_name }}:</td>
                    <td class="td-nowrap td-align-r"><span><span class="currency">{{ taxItem.tax_cost }}</span></span></td>
                    <td class="td-nowrap td-align-r"></td>
                  </tr>
                  <tr
                    v-if="cart.shipping_cost && (cart.shipping_cost + '').replace('$', '').replace(',', '') * 1 > 0"
                  >
                    <td class="td-nowrap">Shipping:</td>
                    <td class="td-nowrap td-align-r"><span><span class="currency">{{ cart.shipping_cost }}</span></span></td>
                    <td class="td-nowrap td-align-r"></td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <div class="divider"></div>
                    </td>
                  </tr>
                  <tr>
                    <td class="td-nowrap"><strong>Total:</strong></td>
                    <td class="td-nowrap td-align-r"><span class="text-larger text-semibold"><span class="currency">{{ cart.total_cost }}</span></span></td>
                    <td class="td-nowrap td-align-r"></td>
                  </tr>
                </tbody>
              </table>
              <input type="hidden" name="checkoutTotalFromServer" :value="cart.total_cost">
              <input type="hidden" name="checkoutShippingFromForm" value="0.00">
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.cart-menu-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 999999; /** so cart menu shows up over overlay and OEM assembly parts image at full screen */
  background-color: rgba(0, 0, 0, 0.8);
}
.cart-menu-container {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  max-width: 420px;
  background-color: #fff;
  color: #000;
  -webkit-box-shadow: -4px 0px 18px -7px #000000;
  box-shadow: -4px 0px 18px -7px #000000;
  header {
    background-color: #f5f5f5;
    color: #000;
    position: relative;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    button {
      position: absolute;
      left: 10px;
      top: 10px;
      background-color: transparent;
      color: #000;
      outline: none;
      img {
        width: 15px;
        height: 15px;
      }
    }
  }
}
.cart-menu {
  padding: 16px;
  &.loading {
    .checkout-totals-table {
      .currency {
        opacity: 0.2;
      }
    }
  }
}
</style>

<script>
/* eslint-disable no-useless-catch */
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  data () {
    return {
      menuSlideIn: false,
      deletingItems: [],
      // If multiple items are deleted at once, to prevent a race condition, load the cart
      // immediately after to get the exact totals
      refreshCartAfterDeletes: false,
      loadingCheckout: false
    }
  },
  computed: {
    ...mapState(['cartMenuOpen', 'user', 'cart', 'cartLoading']),
    products () {
      return ((this.cart && this.cart.products) || [])
        .filter(p => !this.deletingItems.includes(p))
    },
    itemCount () {
      return this.cart?.product_count || 0
    }
  },
  watch: {
    cartMenuOpen () {
      if (this.cartMenuOpen) {
        this.loadCart()
      }
    }
  },
  methods: {
    ...mapMutations(['cartMenu']),
    ...mapActions(['loadCart', 'deleteFromCart']),
    async deleteItem (type, item) {
      this.deletingItems.push(item)
      if (this.deletingItems.length > 1) {
        this.refreshCartAfterDeletes = true
      }
      try {
        await this.$store.dispatch('deleteFromCart', { type, id: item.cartid || item.gcindex })
        this.deletingItems = this.deletingItems.filter(p => p !== item)
        if (!this.deletingItems.length && this.refreshCartAfterDeletes) {
          this.loadCart()
        }
      } catch (e) {
        this.deletingItems = this.deletingItems.filter(p => p !== item)
        this.loadCart()
        throw e
      }
    },
    checkout () {
      this.loadingCheckout = true
      window.location = '/cart.php'
    }
  }
}
</script>
