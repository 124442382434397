<template>
  <div>
    <div
      v-if="bodyRows && bodyRows.length > limit"
    >
      <label
        for="partnumbers-search"
        class="hidden"
      >Search Part Numbers</label>
      <input
        id="partnumbers-search"
        type="text"
        placeholder="Search Part Numbers"
        v-model="searchQuery"
        class="margin-bottom-s width-100"
        style="max-width: 800px"
        aria-label="Search Part Numbers"
      />
    </div>
    <table
      :id="tableId"
      :class="tableClass"
    >
      <thead
        v-if="headRows && headRows.length"
      >
        <tr
          v-for="({ el, children }, $rowIndex) in headRows"
          :key="$rowIndex"
          :id="el.id"
          :class="Array.from(el.classList)"
        >
          <th
            v-for="(rowCol, $rowColIndex) in children"
            :key="$rowColIndex"
            :id="rowCol.id"
            :class="Array.from(rowCol.classList)"
            v-html="rowCol.innerHTML"
          />
        </tr>
      </thead>
      <tbody
        v-if="bodyRows && bodyRows.length"
      >
        <tr
          v-for="({ el, children }, $rowIndex) in (bodyRowPages[page] || [])"
          :key="$rowIndex"
          :id="el.id"
          :class="Array.from(el.classList)"
        >
          <td
            v-for="(rowCol, $rowColIndex) in children"
            :key="$rowColIndex"
            :id="rowCol.id"
            :class="Array.from(rowCol.classList)"
            v-html="rowCol.innerHTML"
          />
        </tr>
      </tbody>
    </table>
    <div
      v-if="bodyRows && bodyRows.length > limit"
      class="pagination flex flex-wrap flex-halign-center flex-valign-middle"
    >
      <div class="flex flex-halign-even flex-valign-middle text-no-select">
        <a
          class="page highlight"
          @click="page > 0 ? goToPage(page - 1) : null"
          :disabled="page <= 0"
          :style="page <= 0 ? { opacity: 0 } : {}"
        >&lt; <span>Prev</span></a>
        <div class="text-small text-semibold dark-grey margin-left-l margin-right-l">
          {{ from }} to {{ to }} of {{ filteredBodyRows.length }}
        </div>
        <a
          class="page highlight"
          @click="page < bodyRowPages.length - 1 ? goToPage(page + 1) : null"
          :disabled="page >= bodyRowPages.length - 1"
          :style="page >= bodyRowPages.length - 1 ? { opacity: 0 } : {}"
        ><span>Next</span> &gt;</a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pagination {
  margin: 2em 0;
}
.pagination a.highlight {
  border: 1px solid #e9e9e9;
  border-radius: 6px;
}
.page {
  padding: 1em;
  font-weight: bold;
  text-decoration: none;
  white-space: nowrap;
}
.pagination-pages {
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
@media (max-width: 800px) {
  .pagination-count {
    margin-bottom: .5em;
    text-align: center;
    width: 100%;
  }
  .pagination-pages,
  .pagination {
    width: 100%;
  }
}
</style>

<script>
import { chunk, min } from 'lodash'

const parseBodyElement = (el) => {
  return Array.from((el && el.children) || []).map(row => {
    return {
      el: row,
      children: Array.from(row.children)
    }
  })
}

export default {
  props: {
    domTable: {
      type: [Object, HTMLTableElement],
      required: true
    }
  },
  data () {
    return {
      page: 0,
      limit: 10,
      searchQuery: null
    }
  },
  computed: {
    tableId () {
      return this.domTable.id
    },
    tableClass () {
      return Array.from(this.domTable.classList)
    },
    headRows () {
      const head = Array.from(this.domTable.children).find(child => child.tagName === 'THEAD')
      return parseBodyElement(head)
    },
    bodyRows () {
      const body = Array.from(this.domTable.children).find(child => child.tagName === 'TBODY')
      return parseBodyElement(body)
    },
    filteredBodyRows () {
      if (!this.searchQuery) {
        return this.bodyRows
      }

      return this.bodyRows.filter(({ children }) => {
        let rowMatches = false
        children.forEach(col => {
          if (col.innerText && col.innerText.toLowerCase().includes(this.searchQuery.toLowerCase())) {
            rowMatches = true
          }
        })
        return rowMatches
      })
    },
    bodyRowPages () {
      return chunk(this.filteredBodyRows, this.limit)
    },
    from () {
      return (this.page * this.limit) + 1
    },
    to () {
      return min([
        (this.page + 1) * this.limit,
        this.filteredBodyRows.length
      ])
    }
  },
  methods: {
    goToPage (page) {
      this.page = page
      // Scroll to top of element when page is changed
      if (window.outerWidth < 800) {
        document.scrollingElement.scrollTop = this.$el.offsetTop - 100
      }
    }
  }
}
</script>
