<template>
  <transition name="menu-fade" @before-enter="initializeSelectedMenuItem" @after-enter="menuSlideIn = true" @before-leave="menuSlideIn = false">
    <nav
      v-if="mobileMenuOpen"
      id="mobile-nav"
      class="mobile-only"
      @click.prevent="closeMenu"
    >
      <div
        class="nav-close-button"
        @click.stop="closeMenu"
      >
        <img src="/images/icons/mobile-menu-close.png" />
      </div>
      <transition name="menu-appear">
        <div
          v-if="menuSlideIn"
          class="mobile-nav-content"
          @click.stop
        >
          <transition-group class="menus-wrapper" :name="slideLeft ? 'menu-slide' : 'menu-slide-right'">
            <div
              :key="'menu-' + (currentMenuItem.cdseo_url || '') + (currentMenuItem.categoryid || '') + (currentMenuItem.isShowAll ? '-sub' : '')"
              style="width: 100%"
            >
              <div
                v-if="menuHeader"
                class="menu-go-up"
                @click="goUp"
              >
                <img src="/images/icons/menu-mobile-arrow-left.png" />
                {{ menuHeader }}
              </div>
              <div
                v-if="menuSubHeader"
                class="menu-subheader green-dark"
              >
                {{ menuSubHeader }}
              </div>
              <ul>

                <li
                  v-for="(menuItem, $menuItemIndex) in menuItems"
                  :key="'mobile-menu-' + $menuItemIndex + (menuItem.isShowAll ? '-sub' : '')"
                >
                  <a
                    :href="menuItem.cdseo_url"
                    @click.prevent="selectMenuItem(menuItem)"
                  >
                    {{ menuItem.category }}

                    <img
                      v-if="menuItem.children && menuItem.children.length"
                      src="/images/icons/menu-mobile-arrow-right.png"
                    />
                  </a>
                </li>
                <!-- Don't show View All for "Riding Style" (891) -->
                <li
                  v-if="currentMenuItem.parent && currentMenuItem.children && currentMenuItem.children.length && currentMenuItem.categoryid !== '891'"
                >
                  <a
                    :href="currentMenuItem.cdseo_url"
                    @click.prevent="selectItem(currentMenuItem)"
                  >
                    View All
                  </a>
                </li>
                <template v-if="!currentMenuItem.parent">
                  <li
                    v-for="(subMenuItem, $subMenuItemIndex) in subMenuItems"
                    :key="'submenu-' + $subMenuItemIndex"
                  >
                    <a
                      class="not-bold"
                      :href="subMenuItem.url"
                      @click.prevent="navigateToPath(subMenuItem.url)"
                    >
                      {{ subMenuItem.name }}
                    </a>
                  </li>
                </template>
              </ul>
            </div>
          </transition-group>
        </div>
      </transition>
    </nav>
  </transition>
</template>

<style lang="scss" scoped>
#mobile-nav {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.8);
  .nav-close-button {
    position: fixed;
    left: 325px;
    top: 10px;
    color: #fff;
    font-weight: bold;
    font-size: 2em;
  }
  .mobile-nav-content {
    overflow-y: auto;
    width: 300px;
    height: 100%;
    background-color: #fff;
    position: relative;
    &.menu-appear-enter-active {
      transition: transform 0.15s ease;
    }
    &.menu-appear-leave-active {
      transition: transform 0.15s ease;
    }
    &.menu-appear-enter, &.menu-appear-leave-to {
      transform: translateX(-100%);
    }
    .menu-go-up {
      text-transform: uppercase;
      padding: 0.8em 1em;
      font-weight: 700;
      border-bottom: 1px solid #ddd;
      position: relative;
      cursor: pointer;
      img {
        padding-right: 1em;
        vertical-align: middle;
        height: 1em;
        margin-top: -4px;
      }
    }
    .menu-subheader {
      text-transform: uppercase;
      padding: 0.8em 2em;
      font-weight: 700;
      border-bottom: 1px solid #ddd;
      position: relative;
      cursor: pointer;
    }
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        position: relative;
        padding: 0;
        cursor: pointer;
        border-bottom: 1px solid #f3f3f3;
        a {
          display: block;
          padding: .8em 2em;
          color: #000;
          text-transform: none;
          font-weight: 600;
          text-decoration: none;
          img {
            margin-top: 3px;
            float: right;
            vertical-align: middle;
          }
          &.not-bold {
            font-weight: normal;
          }
        }
      }
    }
  }
}
</style>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import jQuery from 'jquery'
import { orderBy } from 'lodash'

export default {
  data () {
    return {
      menuSlideIn: false,
      slideLeft: false,
      selectedMenuItem: null
    }
  },
  computed: {
    ...mapState(['mobileMenuOpen']),
    ...mapGetters(['rootMenuItem', 'routeMenuItem']),
    menuItems () {
      const menuItem = this.currentMenuItem
      return ((menuItem && menuItem.children) || [])
        // .filter(item => !!(item.featured * 1))
        .map(item => ({
          ...item,
          children: orderBy(
            (item.children || []).map(i => ({ ...i, order_by: i.order_by * 1 })),
            ['order_by']
          )// (item.children || []) // .filter(child => !!(child.featured * 1))
        }))
    },
    menuHeader () {
      if (!(this.currentMenuItem && this.currentMenuItem.parent)) {
        return null
      }
      return (this.currentMenuItem && this.currentMenuItem.parent && this.currentMenuItem.parent.category) || 'Main Menu'
    },
    menuSubHeader () {
      if (this.currentMenuItem && this.currentMenuItem.category) {
        return this.currentMenuItem.category
      }
      return 'Main Menu'
    },
    currentCategoryId () {
      return (this.currentMenuItem && this.currentMenuItem.categoryid) || '0'
    },
    currentMenuItem () {
      const selectedItem = this.selectedMenuItem || this.routeMenuItem || this.rootMenuItem
      if (selectedItem) {
        if (!(selectedItem.children && selectedItem.children.length) && selectedItem.parent) {
          return selectedItem.parent
        }
      }
      return selectedItem
    },
    subMenuItems () {
      return [
        {
          name: 'Brands',
          url: '/brands/'
        },
        {
          name: 'Expert Advice',
          url: '/expert-advice/'
        },
        {
          name: 'Shop By Activity',
          url: '/shop-by-activity.html'
        },
        {
          name: 'Customer Support',
          url: '/support/'
        }
      ]
    }
  },
  methods: {
    ...mapMutations(['mobileMenu']),
    goUp () {
      this.slideLeft = false
      if (this.currentMenuItem && this.currentMenuItem.parent) {
        this.selectedMenuItem = this.currentMenuItem.parent
      }
    },
    selectItem (menuItem) {
      if (this.$route.path !== menuItem.cdseo_url) {
        this.$router.push({ path: menuItem.cdseo_url }).catch(e => {})
      }
      this.closeMenu()
    },
    closeMenu () {
      this.selectedMenuItem = null
      this.mobileMenu(false)
    },
    selectMenuItem (menuItem) {
      if (menuItem && menuItem.children && menuItem.children.length) {
        this.slideLeft = true
        this.selectedMenuItem = menuItem
      } else {
        this.selectItem(menuItem)
      }
    },
    navigateToPath (path) {
      this.$router.push({ path }).catch(e => {})
      this.mobileMenu(false)
    },
    initializeSelectedMenuItem () {
      this.selectedMenuItem = null
      // If the current menu item doesn't have any items in it, go up one
      if (!this.menuItems.length) {
        this.goUp()
      }
    }
  },
  watch: {
    mobileMenuOpen () {
      // Stop body from scrolling when menu is open
      jQuery('body, html')[this.mobileMenuOpen ? 'addClass' : 'removeClass']('overflow-hidden')
    }
  }
}
</script>
