import Vue from 'vue'
import TopBarLinks from './TopBarLinks'
import AccountModal from './AccountModal'
import CartMenu from './CartMenu'

export default ({ router, store }) => {
  new Vue({
    router,
    store,
    render: h => h(TopBarLinks)
  }).$mount('.top-bar-links')

  // All these components should be initialized outside of top bar so
  // top bar's position: fixed is independent
  new Vue({
    router,
    store,
    render: h => h(AccountModal)
  }).$mount('#account-modal')

  new Vue({
    router,
    store,
    render: h => h(CartMenu)
  }).$mount('#cart-menu')
}
