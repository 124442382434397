<template>
  <div
    ref="swiperContainer"
    class="banners swiper-container"
    :id="(id || '').replace('#', '')"
  >
    <div class="swiper-wrapper">
      <div
        v-for="(item, $index) in items"
        :key="'item' + $index"
        class="banner swiper-slide"
        :style="{ background: `url('${item.image}') center center` }"
      >
        <div class="container">
          <div class="banner-bg">
            <div
              v-if="item.title"
              class="banner-title"
            >
              {{ item.title }}
            </div>
            <div
              v-if="item.text"
              class="banner-text"
            >
              {{ item.text }}
            </div>
            <a
              v-if="item.title && item.url"
              :href="item.url"
              class="banner-button"
              @click.prevent="$router.push({ path: item.url }).catch(e => {})"
            >Shop Now</a>
          </div>
        </div>
      </div>
    </div>

    <div class="swiper-pagination" ref="swiperPagination"></div>

    <div @click="swiper.slideNext()" class="swiper-button-next swiper-button-white"></div>
    <div @click="swiper.slidePrev()" class="swiper-button-prev swiper-button-white"></div>
  </div>
</template>

<style lang="scss">
.banners {
  .banner {
    width: 100%;
    height: 250px;
    background-size: cover !important;
  }
  .swiper-button-next, .swiper-button-prev {
    opacity: 0.4;
  }
  @media (max-width: 1000px) {
    // .swiper-button-next, .swiper-button-prev {
    //   display: none;
    // }
    .banner {
      display: flex;
      align-items: center;
    }
  }
  .banner-title {
    font-size: 1.6em;
    font-weight: bold;
    text-align: center;
    filter: drop-shadow(2px 2px 4px #000);
    text-shadow: #292929 0px 0px 1px;
  }
  .banner-text {
    display: none;
    font-weight: 600;
    text-align: center;
    filter: drop-shadow(2px 2px 4px #000);
    text-shadow: #292929 0px 0px 1px;
  }
  .banner-button {
    float: none !important;
  }
  .banner-bg {
    color: #fff;
    padding: 2em;
    border-radius: 6px;
    margin: 0 auto;
    width: 80%;
    max-width: 1240px;
    min-width: 300px;
    text-align: center;
  }
  @media (min-width: 1000px) {
    .banner-title {
      font-size: 2em;
    }
    .banner-text {
      display: block;
    }
    .banner-bg {
      margin: 4em 0em 0em 0em;
      float: right;
      border-radius: 6px;
      width: 60%;
    }
    .banner-button {
      float: left;
    }
  }
  @media (min-width: 800px) {
    .banner {
      height: 300px;
    }
    .banner-title {
      font-size: 2.4em !important;
      line-height: 1.2em !important;
    }
    .banner-bg {
      float: none !important;
      margin: 0 auto !important;
    }
    .banner-text {
      margin-top: .4em !important;
    }
  }
  @media (min-width: 1000px) {
    .banner {
      height: 350px;
    }
    .banner-bg {
      padding-top: 6em !important;
    }
  }
  @media (max-width: 1000px) {
    .swiper-pagination-bullets {
      bottom: 0 !important;
    }
  }
  .swiper-pagination-bullet {
    opacity: 0.6;
  }
  .swiper-pagination-bullet-active {
    background-color: #888;
    opacity: 1;
  }
}
</style>

<script>
import Swiper from 'swiper'

export default {
  props: {
    id: {
      type: String,
      default: null
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    config () {
      return {
        direction: 'horizontal',
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        ...(this.$refs.swiperPagination ? {
          pagination: {
            el: this.$refs.swiperPagination
          }
        } : {}),
        speed: 500,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        }
      }
    }
  },
  async mounted () {
    this.swiper = new Swiper(this.$refs.swiperContainer, this.config)
  }
}
</script>
