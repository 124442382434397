import PaginatedTable from './PaginatedTable'
import Vue from 'vue'

export default ({ router, store }) => {
  const initializePaginatedTable = (selector) => {
    if (!selector) {
      return
    }
    const el = document.querySelector(selector)

    new Vue({
      router,
      store,
      render: h => h(PaginatedTable, {
        props: {
          domTable: el
        }
      })
    }).$mount(el)
  }

  if (window.initializePaginatedTable) {
    window.initializePaginatedTable.forEach(id => initializePaginatedTable(id))
  }
  window.initializePaginatedTable = initializePaginatedTable
}
