import Vue from 'vue'
import router from '@/router'
import store2 from '@/store'
import Brands from '@/components/Brands'
import MenuDesktop from '@/components/MenuDesktop'
import MenuMobile from '@/components/MenuMobile'
import initAccount from '@/components/Account'
import initPaginatedTable from '@/components/PaginatedTable'
import ProductSlider from '@/components/ProductSlider'
import initProductImages from '@/components/ProductImages'
import LargeBannerSlider from '@/components/LargeBannerSlider'
import jQuery from 'jquery'
import '@/assets/styles.scss'
import 'swiper/swiper-bundle.css'

window.$ = window.jQuery = jQuery // Make jQuery available globally
// Keep a reference to all vm instances that were instantiated manually and
// manually destroy them anytime a route changes
window.vmInstances = []

Vue.config.productionTip = false
Vue.config.devtools = true

// makes it work locally
const store = window.store || store2;

// Putting this in an IIFE to prevent multiple inits when running locally
// Won't affect prod
(() => {
  if (window.vueInitialized) {
    return
  }

  window.vueInitialized = true

  // Style component which makes it possible to embed styles
  // This is very useful for modals and overlays when needing to add
  // styles for the duration of the modal being open without having to manage
  // a stack of instances and knowing when to add/remove overflow:hidden to body
  Vue.component('v-style', {
    render: function (createElement) {
      return createElement('style', this.$slots.default)
    }
  })

  // Attempt to load session data if available
  store.dispatch('getSession')

  initAccount({ router, store })

  // Load the desktop menu automatically
  new Vue({
    router,
    store,
    render: h => h(MenuDesktop)
  }).$mount('#main-nav')

  // Load the mobile menu automatically
  new Vue({
    router,
    store,
    render: h => h(MenuMobile)
  }).$mount('#mobile-nav')

  initPaginatedTable({ router, store })
  initProductImages({ router, store })

  // Brands
  const initializeBrands = (selector = 'div.content') => {
    window.brands = jQuery.makeArray(jQuery('.brands a').map((a, el) => ({ name: el.text, url: el.getAttribute('href') })))
    new Vue({
      router,
      store,
      render: h => h(Brands)
    }).$mount(selector)
  }

  if (window.initializeBrands === true) {
    initializeBrands()
  }
  window.initializeBrands = initializeBrands

  // Product Slider
  // It's assumed that "selector" will be the id of a div that contains multiple "a" tags
  window.initializeProductSlider = (selector) => {
    if (!selector) {
      throw new Error('no selector passed into initializeProductSlider')
    }
    const products = jQuery.makeArray(jQuery(selector + ' a').map((a, el) => {
      el = jQuery(el)
      return {
        url: el.attr('href'),
        el
      }
    }))

    const vm = new Vue({
      router,
      store,
      render: h => h(
        ProductSlider,
        {
          props: {
            id: selector.startsWith('#') ? selector.split(' ')[0] : undefined,
            products
          }
        }
      )
    })
    window.vmInstances.push(vm)
    vm.$mount(selector)
  }

  // If this is defined, automatically initialize for all elements in this array of selectors
  // For instance, if window.initializeProductSliders = ['#slider-trending', '#slider-featured', '#slider-new']
  // then it will initialize this for all of those elements as soon as this js file loads
  if (Array.isArray(window.initializeProductSliders)) {
    window.initializeProductSliders.forEach(window.initializeProductSlider)
  }

  // Large Banner Slider
  // It's assumed that "selector" will be the id of a div that contains multiple "a" tags
  window.initializeLargeBannerSlider = (selector) => {
    if (!selector) {
      throw new Error('no selector passed into initializeLargeBannerSlider')
    }
    const items = jQuery.makeArray(jQuery(selector + ' .banner').map((a, el) => {
      el = jQuery(el)
      return {
        url: el.find('a').attr('href'),
        text: el.find('.banner-text').text().trim(),
        title: el.find('.banner-title').text().trim(),
        image: (el.css('background-image').split('url("')[1] || '').split('")')[0].trim()
      }
    }))

    const vm = new Vue({
      router,
      store,
      render: h => h(
        LargeBannerSlider,
        {
          props: {
            id: selector.startsWith('#') ? selector.split(' ')[0] : undefined,
            items
          }
        }
      )
    })
    window.vmInstances.push(vm)
    vm.$mount(selector)
  }

  // If this is defined, automatically initialize for all elements in this array of selectors
  // For instance, if window.initializeLargeBannerSliders = ['#slider-trending', '#slider-featured', '#slider-new']
  // then it will initialize this for all of those elements as soon as this js file loads
  if (Array.isArray(window.initializeLargeBannerSliders)) {
    window.initializeLargeBannerSliders.forEach(window.initializeLargeBannerSlider)
  }
})()
