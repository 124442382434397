import { render, staticRenderFns } from "./CartMenu.vue?vue&type=template&id=0935db62&scoped=true&"
import script from "./CartMenu.vue?vue&type=script&lang=js&"
export * from "./CartMenu.vue?vue&type=script&lang=js&"
import style0 from "./CartMenu.vue?vue&type=style&index=0&id=0935db62&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0935db62",
  null
  
)

export default component.exports