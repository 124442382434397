<template>
  <div class="content">
    <h1>Our Brands</h1>
    <div class="divider" />
    <div class="brands-search-container lightest-grey-bg padding-m">
      <div class="center">
        <form @submit.prevent="submit">
          <label for="brands-search" class="hidden">Brand Search</label>
          <input
            v-model="searchQuery"
            @input="searchQueryInput"
            type="text"
            id="brands-search"
            name="q"
            value=""
            title="Type a Brand Name to Search For It"
            placeholder="Find a Brand"
            class="brands-search-input"
            maxlength="128"
            autocomplete="off"
            aria-label="Type a Brand Name to Search For It"
            autofocus="true"
          >
        </form>
      </div>
      <div
        v-if="filteredBrands.length !== 1"
        class="brands-letters flex flex-halign-center flex-valign-middle flex-wrap"
      >
        <a
          v-for="brandByLetter in filteredBrandsByLetter"
          :key="'letter-link' + brandByLetter[0]"
          :href="`#${brandByLetter[0] === '#' ? 'num' : brandByLetter[0]}`"
          class="anchor"
          :title="`Brands starting with ${brandByLetter[0]}`"
        >
          {{ brandByLetter[0] }}
        </a>
        <div class="fence"></div>
        <div id="brands-count" class="text-small text-bold">{{ filteredBrands.length }} Brands</div>
      </div>
      <div class="clearing"></div>
    </div>
    <template
      v-for="brandByLetter in filteredBrandsByLetter"
    >
      <div
        :key="'letter-' + brandByLetter[0]"
        class="brands-title"
        :id="brandByLetter[0] === '#' ? 'num' : brandByLetter[0]"
      >
        {{brandByLetter[0]}}
      </div>
      <div
        :key="'brands-' + brandByLetter[0]"
        class="brands"
      >
        <a
          v-for="brand in brandByLetter[1]"
          :key="brand.url"
          :href="brand.url"
          @click.prevent="$router.push({ path: brand.url }).catch(e => {})"
        >
          {{brand.name}}
        </a>
      </div>
      <div
        :key="'clearing-' + brandByLetter[0]"
        class="clearing"
      />
    </template>
  </div>
</template>

<script>
const getBrandsByLetter = (brands) => {
  const letterKeys = {}
  brands.forEach(brand => {
    if (!brand.name) {
      return
    }
    let firstLetter = ((brand.name || '').trim()[0] || '').toUpperCase()
    if (!isNaN(firstLetter * 1)) {
      firstLetter = '#'
    }
    letterKeys[firstLetter] = letterKeys[firstLetter] || []
    letterKeys[firstLetter].push(brand)
  })
  const result = Object.entries(letterKeys).sort((a, b) => a[0] - b[0])

  return result
}

export default {
  name: 'Brands',
  data () {
    return {
      searchQuery: ''
    }
  },
  computed: {
    routeSearchQuery () {
      return (this.$route && this.$route.query && this.$route.query.let) || ''
    },
    brands () {
      return window.brands
    },
    brandsByLetter () {
      return getBrandsByLetter(this.brands)
    },
    filteredBrands () {
      const searchQuery = (this.searchQuery || '').replace(/[^A-Za-z0-9\s]/g, '').toLowerCase()
      if (!searchQuery) {
        return this.brands
      }
      // Possibility of supporting multiple terms
      const terms = [searchQuery] // searchQuery.split(' ').filter(term => !!term)
      return this.brands.filter(brand => {
        if (brand && brand.name) {
          const brandName = brand.name.replace(/[^A-Za-z0-9\s]/g, '').toLowerCase()
          return terms.reduce((acc, term) => {
            return acc || (brandName.startsWith(term))
          }, false)
        }
        return false
      })
    },
    filteredBrandsByLetter () {
      return getBrandsByLetter(this.filteredBrands)
    }
  },
  watch: {
    'routeSearchQuery' () {
      this.searchQuery = this.routeSearchQuery
    }
  },
  mounted () {
    if (this.routeSearchQuery) {
      this.searchQuery = this.routeSearchQuery
    }
  },
  methods: {
    searchQueryInput () {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$router.push({ path: window.location.pathname, query: this.searchQuery ? { let: this.searchQuery } : {} }).catch(e => {})
      }, 500)
    },
    submit () {
      if (this.filteredBrands.length <= 3) {
        this.$router.push({ path: this.filteredBrands[0].url }).catch(e => {})
      } else {
        // console.log('do nothing')
      }
    }
  }
}
</script>
