import ProductImages from './ProductImages'
import Vue from 'vue'

export default ({ router, store }) => {
  const initializeProductImages = (selector = '#product-container-image') => {
    new Vue({
      router,
      store,
      render: h => h(ProductImages)
    }).$mount(selector)
  }

  if (window.initializeProductImages) {
    initializeProductImages(window.initializeProductImages)
  }
  window.initializeProductImages = initializeProductImages
}
