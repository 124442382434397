<template>
  <div class="top-bar-links">
    <div class="flex">
      <a
        href="/account/"
        title="Your Account"
        class="top-bar-link"
        id="top-bar-account"
        @click.stop.prevent="!userLoading && $store.dispatch('openAccount')"
      >
        <span>
          <img src="/images/icons/account.png" width="40" height="40" alt="Your Account" />
        </span>
        <span class="desktop-only">
          {{ user && user.firstname ? user.firstname : 'Account' }}
        </span>
      </a>
      <a
        href="/support/"
        title="Customer Service"
        id="top-bar-service"
        class="top-bar-link desktop-only app-link"
      >
        <span>
          <img src="/images/icons/service.png" width="40" height="40" alt="Service" />
        </span>
        <span class="desktop-only"> Service </span>
      </a>
      <a
        title="Your Shopping Cart"
        class="top-bar-link"
        id="top-bar-cart"
        @click.stop="openCart"
      >
        <span>
          <img src="/images/icons/cart.png" width="40" height="40" alt="Your Shopping Cart">
        </span>
        <div
          v-if="cart && cart.product_count > 0"
          class="count-bubble mobile-only"
        >{{ cart.product_count }}</div>
        <span
          class="desktop-only nowrap"
          :style="{ opacity: !(cartLoading && cart === null) ? 1 : 0 }"
        >
          {{ (cart && cart.product_count) || '0' }} Item{{ ((cart && cart.product_count) || 0) * 1 === 1 ? '' : 's' }}
        </span>
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
a {
  cursor: pointer;
  position: relative;
}
.count-bubble {
  position: absolute;
  background-color: #4b9e2b;
  padding: 0 6px;
  font-size: 14px;
  border-radius: 16px;
  top: -2px;
  right: 8px;
}
</style>

<style lang="scss">
.fade-enter-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  data () {
    return {
      showModal: false
    }
  },
  computed: {
    ...mapState(['user', 'userLoading', 'cart', 'cartLoading', 'garageLoading'])
  },
  methods: {
    ...mapMutations(['accountModal', 'cartMenu']),
    ...mapActions(['logout']),
    openCart () {
      if (this.cart?.products?.length) {
        this.cartMenu(true)
      }
    }
  }
}
</script>
