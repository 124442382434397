<template>
  <nav
    id="main-nav"
    class="green-bg desktop-only"
    :class="{ 'display-sticky': scrolledUp }"
  >
    <div class="container top-nav">
      <div class="main-menu-items">
        <div>
          <a
            v-for="(menuItem, $index) in menuItems"
            :key="'menu' + $index"
            :href="menuItem.cdseo_url"
            :title="menuItem.link_title"
            class="item"
            :class="{ first: $index === 0, hovered: hoveredMenuItem === menuItem }"
            @mouseover="queueHoverMenu(menuItem)"
            @mouseleave="hideHoverMenu(menuItem)"
            @click.prevent="selectMenuItem(menuItem)"
          >
            {{ menuItem.category }}
          </a>
        </div>
      </div>
      <div class="green-dark-bg extra-menu-items">
        <div>
          <a class="item" href="/brands/" title="Our Brands" @click.prevent="navigateTo('/brands/' )">Brands</a>
          <a class="item" href="/expert-advice/" title="Expert Advice" @click.prevent="navigateTo('/expert-advice/')">Advice</a>
          <a class="item" href="/shop-by-activity.html" title="Shop By Activity" @click.prevent="navigateTo('/shop-by-activity.html')">By Activity</a>
        </div>
      </div>
    </div>
    <div
      v-if="hoveredMenuItem"
      class="container sub-nav-wrapper"
      @mouseover="queueHoverMenu(hoveredMenuItem)"
      @mouseleave="hideHoverMenu(hoveredMenuItem)"
    >
      <div
        class="sub-nav"
        :class="{ 'small-items': hoveredMenuItem.categoryid + '' === '485' }"
      >
        <div
          class="items-wrapper"
        >
          <div
            v-if="hoveredMenuItem.image_url"
            class="menu-image"
            :key="hoveredMenuItem.image_url"
            :style="{ backgroundImage: 'url(\'' + hoveredMenuItem.image_url + '\')' }"
          />
          <div
            class="items"
            :style="['890', '891'].includes(hoveredMenuItem.categoryid + '') ? {} : {
              maxHeight: itemsMaxHeight + 'px',
              marginBottom: itemsMarginBottom + 'px'
            }"
          >
            <div class="item"
              v-for="(subMenuItem, $subMenuIndex) in subMenuItems"
              :key="'sub-menu' + (subMenuItem.categoryid || $subMenuIndex)"
            >
              <a
                :href="subMenuItem.cdseo_url"
                :title="subMenuItem.link_title"
                @click.prevent="selectMenuItem(subMenuItem)"
              >
                <!-- <div
                  v-if="subMenuItem.image_url"
                >
                  <img
                    :src="subMenuItem.image_url"
                  />
                </div> -->
                {{ subMenuItem.category }}
              </a>
              <div
                v-if="subMenuItem.children.length && !['890', '891'].includes(hoveredMenuItem.categoryid + '')"
                class="sub-children"
              >
                <div
                  v-for="(subMenuItemChild, $subMenuChildIndex) in subMenuItem.children"
                  :key="'sub-menu-child' + (subMenuItemChild.categoryid || $subMenuChildIndex)"
                >
                  <a
                    :href="subMenuItem.cdseo_url"
                    @click.prevent="selectMenuItem(subMenuItemChild)"
                  >
                    {{ subMenuItemChild.category }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="relatedBrands && relatedBrands.length"
            class="related-brands"
          >
            <a
              v-for="(relatedBrand, $rbIndex) in relatedBrands"
              :key="'rb' + $rbIndex"
              :href="relatedBrand.brand_page_url"
              @click.prevent="navigateTo(relatedBrand.brand_page_url)"
            >
              <img :src="relatedBrand.brand_image" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<style lang="scss">
#main-nav {
  overflow: visible;
  max-height: 54px;
  z-index: 99;
  position: sticky;
  transition: top 0.2s ease-in-out;
  top: 0;
  left: 0;
  right: 0;
  &.display-sticky {
    top: 96px;
  }
  .item {
    text-decoration: none;
    font-weight: 600;
    font-size: .9em;
    margin-top: 1px;
    border-radius: 4px 4px 0 0;
    display: inline-block;
    white-space: nowrap;
    a {
      text-decoration: none;
    }
  }
  .top-nav {
    .main-menu-items {
      display: inline-block;
      padding-right: 1%;
      vertical-align: top;
      > div {
        display: flex;
        justify-content: space-between;
      }
    }
    .extra-menu-items {
      display: inline-block;
      text-align: center;
      vertical-align: top;
      > div {
        display: flex;
        justify-content: space-around;
      }
    }
    .item {
      text-transform: uppercase;
      color: #fff;
      vertical-align: top;
      padding-top: 1.1em;
      padding-bottom: 1.1em;
      text-align: center;
      flex-grow: 1;
      &:hover, &.hovered {
        background-color: #fff;
        color: #000;
      }
    }
  }
  .green-dark-bg a:hover {
    background-color: #b71a20;
  }

  .sub-nav-wrapper {
    position: relative;
  }
  .sub-nav {
    position: absolute;
    background-color: #fff;
    width: 100%;
    top: 0;
    padding: 5px;
    display: flex;
    flex-direction: row;
    box-shadow: 4px 5px 9px -1px rgba(0,0,0,0.37);
    min-height: 80px !important;
    border-radius: 0 0 6px 6px;
    &.full-width {
      width: 100%;
    }
    &.small-items {
      width: 600px;
      left: 50%;
      margin-left: -300px;
      .items {
        flex-direction: row;
        div.item {
          width: 100%;
        }
      }
      .menu-image {
        display: block !important;
      }
    }
    &.images-with-borders {
      img {
        border: 1px solid #ddd;
      }
    }
    .items-wrapper {
      flex-grow: 100;
      display: flex;
      flex-direction: row;
      .menu-image {
        display: none;
        margin: 8px;
        border-radius: 4px;
        width: 300px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
    .items {
      min-height: 120px;
      padding-bottom: 0.5em;
      align-content: flex-start;
      flex-direction: column;
      flex-wrap: wrap;
      flex-grow: 100;
      display: flex;
      flex-wrap: wrap;
      div.item {
        vertical-align: top;
        display: inline-block;
        text-transform: capitalize;
        color: #000;
        width: 33%;
        padding: .8em .8em .8em 1em;
        font-weight: 600;
        a {
          color: #000;
          font-weight: bold;
        }
        .sub-children {
          margin-top: 0.2em;
          line-height: 2em;
          a {
            font-weight: normal;
            font-size: 1em;
          }
        }
      }
    }
    .related-brands {
      display: flex;
      padding: .75em 1em 0em 1em;
      border-top: 1px solid #F2F2F2;
      a {
        flex: 1;
        padding: 0em 1.2em;
        img {
          width: 100%;
        }
      }
    }
  }

  .sub-nav-html {
    display: flex;
    align-items: center;
    a {
      padding: 0;
      line-height: 1em;
      img {
        margin-top: 0;
        border-radius: 6px;
      }
    }
  }
}

@media (max-height: 800px) {
  #main-nav {
    .sub-nav {
      &:not(.images-single-line) {
        .items {
          flex-direction: row;
          min-height: 220px;
          .sub-children {
            display: none;
          }
        }
      }
    }
  }
}
@media (min-width: 800px) {
  #main-nav {
    .top-nav {
      .main-menu-items {
        width: calc(100% - 189px);
      }
      .sub-nav {
        width: 100%;
      }
    }
    .top-nav .extra-menu-items {
      width: 189px;
    }
  }
}
@media (min-width: 900px) {
  #main-nav {
    .top-nav {
      .main-menu-items {
        width: calc(100% - 207px);
      }
      .extra-menu-items {
        width: 207px;
      }
    }
  }
}
@media (min-width: 1000px) {
  #main-nav {
    .top-nav {
      .main-menu-items {
        width: calc(100% - 229px);
      }
      .extra-menu-items {
        width: 229px;
      }
    }
  }
}
@media (min-width: 1100px) {
  #main-nav {
    .top-nav {
      .main-menu-items {
        width: calc(100% - 249px);
      }
      .extra-menu-items {
        width: 249px;
      }
    }
  }
}
@media (min-width: 1200px) {
  #main-nav {
    .top-nav .main-menu-items, .sub-nav {
      width: calc(100% - 259px);
    }
    .top-nav .extra-menu-items {
      width: 259px;
    }
    .items-wrapper {
      .menu-image {
        display: block !important;
      }
    }
  }
}
@media (max-width: 1200px) {
  #main-nav .sub-nav {
    max-width: none;
    .sub-nav-html {
      display: none !important;
    }
  }
}
</style>

<script>
import { mapGetters } from 'vuex'
import jQuery from 'jquery'
import { orderBy, sum, max } from 'lodash'

export default {
  data () {
    return {
      hoveredMenuItem: null,
      scrolledUp: false
    }
  },
  computed: {
    ...mapGetters(['rootMenuItem']),
    menuItems () {
      return ((this.rootMenuItem && this.rootMenuItem.children) || [])
    },
    subMenuItems () {
      return ((this.hoveredMenuItem && this.hoveredMenuItem.children) || [])
        .sort((a, b) => (a.order_by * 1) - (b.order_by * 1))
        .map(item => {
          return {
            ...item,
            children: orderBy(
              (item.children || []).map(i => ({ ...i, order_by: i.order_by * 1 })),
              ['order_by']
            )
          }
        })
    },
    subSubMenuItemCount () {
      return sum(this.subMenuItems.map(item => item.children.length || 0))
    },
    itemsMaxHeight () {
      let height = (40 + (this.subMenuItems.length * 40 / 3) + (this.subSubMenuItemCount * 43 / 3))
      // Ensure that the height is enough for when a submenu with a lot of subSubMenuItems exists
      const maxSubSubs = max(this.subMenuItems.map(item => item.children.length || 0))
      height = max([height, maxSubSubs * 25])
      if (this.hoveredMenuItem.categoryid + '' === '485') {
        height *= 2
      }
      return height
    },
    // Add a bottom margin to items to keep menu roughly the same height it is with flex-direction: row on < 800px height
    itemsMarginBottom () {
      if (this.itemsMaxHeight < 190) {
        return 190 - this.itemsMaxHeight
      }
      return 0
    },
    relatedBrands () {
      return this.hoveredMenuItem && this.hoveredMenuItem.related_brands
    }
  },
  methods: {
    queueHoverMenu (menuItem) {
      clearTimeout(this.hoverTimeout)
      if (menuItem && menuItem.children && menuItem.children.length) {
        this.hoverTimeout = setTimeout(() => {
          this.hoveredMenuItem = menuItem
        }, this.hoveredMenuItem ? 0 : 200)
      } else {
        this.hoveredMenuItem = null
      }
    },
    hideHoverMenu (menuItem) {
      clearTimeout(this.hoverTimeout)
      this.hoverTimeout = setTimeout(() => {
        this.hoveredMenuItem = null
      }, 50)
    },
    navigateTo (url) {
      this.$router.push({ path: url }).catch(e => {})
      clearTimeout(this.hoverTimeout)
      this.hoveredMenuItem = null
    },
    selectMenuItem (menuItem) {
      if (menuItem.cdseo_url) {
        this.navigateTo(menuItem.cdseo_url)
      }
    }
  },
  watch: {
    $route (to, from) {
      clearTimeout(this.hoverTimeout)
      this.hoveredMenuItem = null
    },
    async hoveredMenuItem () {
      // Look for any links in the menu item html and prevent default click handlers
      // so they can be loaded "ajaxly" instead.
      await this.$nextTick()
      if (this.$refs.subNavHtml) {
        jQuery(this.$refs.subNavHtml).find('a').off('click').on('click', function (event) {
          event.preventDefault()
          const href = jQuery(event.currentTarget).attr('href')
          if (href) {
            window.loadForPath(href)
          }
        })
      }
    }
  },
  mounted () {
    let previousScroll = 0
    let currentScroll = 0
    window.addEventListener('scroll', () => {
      currentScroll = document.scrollingElement.scrollTop
    })

    this.scrollWatcher = window.setInterval(() => {
      if (currentScroll === previousScroll) {
        return // No change if scroll hasn't changed
      }
      this.scrolledUp = currentScroll < previousScroll
      previousScroll = currentScroll
    }, 250)
  },
  beforeDestroy () {
    clearInterval(this.scrollWatcher)
  }
}
</script>
