<template>
  <transition name="fade">
    <div
      v-if="accountModalOpen"
      id="login"
      class="flex flex-valign-middle"
    >
      <div class="login-container center">
        <login
          @close="$store.commit('accountModal', false)"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import Login from './Login'
import { mapState } from 'vuex'

export default {
  components: {
    Login
  },
  computed: {
    ...mapState(['accountModalOpen'])
  }
}
</script>
