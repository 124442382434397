import jQuery from 'jquery'

// Simple wrapper around jQuery to make it promised based
// {
//   method: 'GET',
//   url: '/backend/api/user.php',
//   data: {
//     mode: 'get_session'
//   }
// }
export default async (params) => {
  const response = await new Promise((resolve, reject) => {
    jQuery.ajax({
      ...params,
      success: (data) => {
        try {
          resolve(JSON.parse(data))
        } catch (e) {
          resolve(data)
        }
      },
      error: (xhr, status, error) => {
        reject(error)
      }
    })
  })

  return response
}
