<template>
  <div
    class="sliders white-bg border-radius"
    :id="(id || '').replace('#', '')"
  >
    <div ref="swiperContainer" class="swiper-container">
      <div class="swiper-wrapper">
        <div
          v-for="(product, $index) in products"
          :key="'product' + $index"
          class="swiper-slide"
        >
          <a
            :href="product.url"
            class="slider-product text-small line-height-m"
            @click.prevent="$router.push({ path: product.url }).catch(e => {})"
            v-html="product.el.html()"
          >
          </a>
        </div>
      </div>
    </div>
    <div @click="swiper.slideTo(swiper.realIndex + Math.floor(swiper.params.slidesPerView))" class="swiper-button-next swiper-button-black"></div>
    <div @click="swiper.slideTo(swiper.realIndex - Math.floor(swiper.params.slidesPerView))" class="swiper-button-prev swiper-button-black"></div>

    <div
      class="swiper-scrollbar"
      ref="scrollbar"
      :style="{ visibility: loaded ? 'visible' : 'hidden' }"
    ></div>
  </div>
</template>

<style lang="scss">
.sliders {
  position: relative;
  padding: 0 40px;
  height: 320px;
  border: 1px solid #ccc;
  white-space: nowrap;
  overflow: hidden;
  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -20px;
    margin-left: 5px;
    opacity: 0.2;
    padding: 0 5px;
  }
  .swiper-button-next {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -20px;
    margin-right: 5px;
    opacity: 0.2;
  }
  .swiper-scrollbar {
    width: 100%;
    left: 0;
    bottom: 0;
    height: 5px;
    z-index: 1;
    position: absolute;
  }
  @media all and (max-width: 600px) {
    .swiper-scrollbar {
      display: none;
    }
  }
}
@media all and (max-width: 900px) {
  .sliders {
    padding: 0;
    .swiper-button-prev, .swiper-button-next {
      display: none;
    }
  }
}
.slider-product {
  color: #000;
  padding: 2em .25em;
  text-decoration: none;
  height: 100%;
  width: 100%;
  overflow: hidden;
  justify-content: flex-start;
  align-items: center;
}
.slider-product:hover {
  color: #000;
}
.slider-product div {
  white-space: normal;
}
.slider-product div.name {
  height: 40px;
  overflow: hidden;
}
.slider-img {
  width: 100%;
  height: 150px;
  overflow: hidden;
  text-align: center;
  vertical-align: top;
}
.slider-img img {
  width: 130px;
  height: auto;
}
.gclid-sliders .sliders {
    border: none !important;
  }
  .gclid-sliders .slider-img {
    width: 90px;
    height: 90px;
    display: inline-block;
  }
  .gclid-sliders .slider-img img {
    width: 80px;
    height: auto;
  }
  .gclid-sliders .slider-details {
    padding-left: 1em;
    display: inline-block;
    width: calc(100% - 90px);
    vertical-align: top;
  }
  .gclid-sliders .price-save {
    display: none;
  }
  .gclid-sliders .sliders {
    height: 90px;
  }
  .gclid-sliders .slider-product {
    padding: .5em;
  }
  .gclid-sliders .sliders .swiper-button-prev {
    padding: 0 1em 0 0;
  }
  .gclid-sliders .sliders .swiper-button-next {
    padding: 0 0 0 1em;
  }
</style>

<script>
import Swiper, { Scrollbar } from 'swiper'
import jQuery from 'jquery'

Swiper.use([Scrollbar])

export default {
  props: {
    id: {
      type: String
    },
    products: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      loaded: false,
      isGclid: false
    }
  },
  computed: {
    breakpoints () {
      const centeringProps = (this.products || []).length > 0 && (this.products || []).length < 4 ? {
        centeredSlides: true,
        initialSlide: Math.floor(((this.products || []).length - 1) / 2)
      } : {}

      if (this.isGclid) {
        return {
          450: {
            slidesPerView: 1.3
          },
          700: {
            slidesPerView: 2.3
          },
          1024: {
            slidesPerView: 3.3,
            ...centeringProps
          },
          1200: {
            slidesPerView: 3.3,
            ...centeringProps
          }
        }
      }

      return {
        450: {
          slidesPerView: 2.5
        },
        700: {
          slidesPerView: 3.5
        },
        1024: {
          slidesPerView: 4.5,
          ...centeringProps
        },
        1200: {
          slidesPerView: 5.5,
          ...centeringProps
        }
      }
    },
    config () {
      const vm = this
      return {
        direction: 'horizontal',
        slidesPerView: 1.75,
        spaceBetween: 30,
        loop: false,
        scrollbar: {
          el: this.$refs.scrollbar,
          hide: true
        },
        initialSlide: 0,
        centeredSlides: false,
        breakpoints: this.breakpoints,
        on: {
          slideChangeTransitionStart () {
            vm.loaded = true
          },
          touchStart () {
            vm.loaded = true
          }
        }
      }
    }
  },
  async mounted () {
    this.isGclid = jQuery(this.$el).parents('.gclid-sliders').length > 0
    this.swiper = new Swiper(this.$refs.swiperContainer, this.config)
    // This hides a swiper glitch that causes the scrollbar to flicker for a second on load and then hide
    await new Promise(resolve => setTimeout(() => {
      this.loaded = true
    }, 1000))
  }
}
</script>
