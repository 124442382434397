import Vue from 'vue'
import VueRouter from 'vue-router'
import jQuery from 'jquery'
import { isEqual } from 'lodash'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

// Putting this in an IIFE to prevent multiple inits when running locally
// Won't affect prod
(() => {
  if (window.router) {
    return
  }

  let locTimeout
  let initialLoad = false

  router.beforeEach(async (to, from, next) => {
    // Anytime the router changes destroy any manually instantiated vue components
    // This list is populated in main.js based on the type of component
    (window.vmInstances || []).forEach(vm => vm.$destroy())
    window.vmInstances = []

    // Cancel any outstanding requests
    if (window._currentRequest) {
      window._currentRequest.abort()
    }

    // console.log('before route', to, from) // eslint-disable-line no-console
    next()
  })

  router.afterEach(async (to, from) => {
    // In production ignore the initial load since it is for the same page that will already be rendered.
    // Client-side needs pages loaded however.
    if (!initialLoad && process.env.NODE_ENV === 'production') {
      initialLoad = true
      if (typeof window.afterRouteChange === 'function') {
        window.afterRouteChange(to, from)
      }
      return
    }

    let comparisonKey = 'path' // set this to fullPath if triggering a route change is needed on query string change
    if (to.path.startsWith('/oem/')) {
      comparisonKey = 'fullPath'
      if (from.path.startsWith('/oem/') && from.query.year && to.query.assembly) {
        // Special handling for OEM section
        jQuery('.plps-container').addClass('loading')
        try {
          const response = await jQuery.get(to.fullPath || to.path)
          const oemBreadCrumbs = jQuery(response).find('#oem-breadcrumbs').html()
          if (oemBreadCrumbs) {
            jQuery('#oem-breadcrumbs').html(oemBreadCrumbs)
          }
          const oemModelTitle = jQuery(response).find('#oem-model-title').html()
          if (oemModelTitle) {
            jQuery('#oem-model-title').html(oemModelTitle)
          }
          const oemModelContent = jQuery(response).find('.plps-container').html()
          jQuery('.plps-container').html(oemModelContent)
          jQuery('.plps-container').removeClass('loading')
        } catch (e) {
          jQuery('.plps-container').removeClass('loading').html('<div>We were unable to load this page.</div>')
          throw e
        }
        if (typeof window.afterRouteChange === 'function') {
          window.afterRouteChange(to, from)
        }
        return
      }
    }

    // If all that's changed is the hash, no need to ajax load
    if (to.path === from.path && isEqual(to.query, from.query) && isEqual(to.params, from.params)) {
      if (typeof window.afterRouteChange === 'function') {
        window.afterRouteChange(to, from)
      }
      return
    }

    // If path is the same then don't do any ajax loading since it's likely just a change to the query string
    // If it's desireable to trigger a route change from the query string changing then set comparisonKey to fullPath
    if (to[comparisonKey] === from[comparisonKey] || to[comparisonKey] + '/' === from[comparisonKey] || to[comparisonKey] === from[comparisonKey] + '/') {
      // If the view all query param changed then trigger a page change.
      // If view all exists and is the same or else #plp-mfilters exists then don't trigger a route
      // ajax page change (app-link)
      if (
        // (
        //   Object.keys(to.query).includes('view_all') &&
        //   to.query.view_all === from.query.view_all
        // ) ||
        (
          jQuery('#plp-mfilters').length &&
          (
            !(to.query.q || from.query.q) || // The search page has a filter on it but we want to trigger a full change whenever the search query changes
            to.query.q === from.query.q
          ) &&
          (to.query.subcategoryid || '') === (from.query.subcategoryid || '') // For instances where left filter menu on product pages should load entire page content
        )
      ) {
        if (typeof window.afterRouteChange === 'function') {
          window.afterRouteChange(to, from)
        }
        return
      } else if (to.path === '/brands/') {
        return // Don't pull for brands page
      }
    }

    clearTimeout(locTimeout)
    locTimeout = setTimeout(function () {
      window.location = to.fullPath
    }, 30000)

    jQuery('#app-loading').show()
    if (typeof window.beforePageLoad === 'function') {
      window.beforePageLoad()
    }

    // If user is logged in, pull latest session info to get updated cart count
    // eslint-disable-next-line camelcase
    if (window.store?.state?.user?.id) {
      window.store.dispatch('getSession')
    }

    let requestPath = to.fullPath || to.path
    if (requestPath.includes('?')) {
      requestPath += '&ajax=1'
    } else {
      requestPath += '?ajax=1'
    }
    const filtersAreVisible = jQuery('#plp-mfilters').is(':visible')
    window._currentRequest = jQuery.get(requestPath, async (response, status, xhr) => {
      clearTimeout(locTimeout)
      if (status === 'error') {
        // window.location = to.path
        jQuery('#app-loading').hide()
        clearTimeout(locTimeout)
        return // next(false)
      }

      if (typeof window.pageChange === 'function') {
        let pageTitle
        if (xhr && typeof xhr.getResponseHeader === 'function') {
          pageTitle = xhr.getResponseHeader('x-page-title')
          pageTitle = pageTitle ? decodeURIComponent(pageTitle) : pageTitle
        }
        window.pageChange(to.path, pageTitle)
      }
      // Remove #app-body from response if for some reason it was sent by the server
      if (jQuery(response).find('#app-body').length) {
        response = jQuery(response).find('#app-body').html()
      }
      // console.log('set app body via router') // eslint-disable-line no-console
      jQuery('#app-body').html(response)

      // Show filters again (if we're on products page) since this was likely
      // the result of an .app-link click inside of filters
      if (filtersAreVisible) {
        jQuery('#plp-mfilters').show()
      }

      jQuery('#app-loading').hide()

      if (typeof window.afterRouteChange === 'function') {
        window.afterRouteChange(to, from)
      }
      if (typeof window.afterPageChange === 'function') {
        window.afterPageChange(to, from)
        window.afterPageChange = null
      }
    })
  })

  window.loadForPath = async (path, title) => {
    await router.push({ path }).catch(e => {})
  }

  jQuery('#app-page, #modal')
    .off('click', 'a.app-link')
    .on('click', 'a.app-link', function (event) {
      event.preventDefault()
      event.stopPropagation()
      const path = jQuery(this).attr('href')
      // var title = jQuery(this).attr('title')

      router.push({ path }).catch(e => {})
    })

  window.router = router
})()

export default router
