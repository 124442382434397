<template>
  <form @submit.prevent="submit">
    <div class="flex flex-halign-even">
      <div class="login-logo">
        <img src="/images/logos/logo-250.png" srcset="/images/logos/logo-250.png 800w, /images/logos/logo-150.png 150w" sizes="(min-width: 800px) 150w, 250w" alt="Canadian Outdoor Equipment Co">
      </div>
      <div class="text-semibold login-secure">
        <div class="flex flex-valign-middle">
          <img src="/images/icons/lock_32x32.png" width="32" height="32" alt="Secure">
          <div class="margin-left-s">Secure Login</div>
        </div>
      </div>
    </div>
    <div class="divider grey text-bold"></div>
    <div
      v-if="state === 'force_reset'"
      class="login-area"
    >
      <p>The website has changed since your last login and a password reset is required to ensure your security.  We've now sent a password reset email. If you don't see the email, check your spam folder.</p>
    </div>
    <div
      v-else
      class="login-area"
    >
      <div class="login-text text-larger text-bold margin-top-m">
        {{ instructions }}
      </div>
      <div class="margin-top-m">
        <input
          v-model="email"
          type="email"
          name="login"
          placeholder="Email Address"
          class="text-larger"
          :class="{error: showErrors && !!emailError}"
        >
      </div>
      <transition name="fade">
        <div
          v-if="['login', 'register'].includes(state)"
          class="margin-top-m"
        >
          <input
            v-model="password"
            type="password"
            name="password"
            placeholder="Password"
            class="text-larger"
            :class="{error: showErrors && !!passwordError}"
            ref="passwordField"
            autofocus
          >
        </div>
      </transition>
      <transition name="fade">
        <p
          v-if="showErrors && (emailError || passwordError || requestError)"
          class="error"
        >
          {{ emailError || passwordError || requestError }}
        </p>
        <p
          v-else-if="successMessage"
          class="green"
        >
          {{ successMessage }}
        </p>
      </transition>
      <div class="margin-top-m">
        <input
          :disabled="!canSubmit"
          :class="{'button-disabled': !canSubmit}"
          type="submit"
          :value="submitText"
          class="button text-larger width-100"
          @click="submit"
        >
      </div>
      <div class="margin-top-l">
        <a
          @click="$emit('close')"
          class="text-nobold"
        >Cancel</a>
        <div class="fence light-grey">|</div>
        <a
          v-if="state === 'lost_pass'"
          @click="handleLookupStatus"
          class="text-nobold"
        >Register / Login</a>
        <a
          v-else
          @click="state = 'lost_pass'"
          class="text-nobold"
        >Forgot Password</a>
      </div>
      <div class="divider"></div>
      <div class="text-tiny">
        By signing in or creating an account, you agree to our <a href="/privacy-policy/" title="Privacy Policy" target="_blank">privacy policy</a> and <a href="/terms-and-conditions/" title="Terms of Use" target="_blank">conditions of use</a>.
      </div>
    </div>
  </form>
</template>

<style lang="scss">
#login {
  background-color: #fff;
  bottom: 0;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
}
.login-logo img {
  max-width: 250px;
  height: auto;
}
.login-container {
  background-color: #fff;
  height: 50vh;
  max-width: 800px;
  min-height: 500px;
  margin: 0 auto;
  margin-top: -160px;
  padding: 20px;
  width: 100%;
}
.login-text {
  margin: 0 1.5em;
}
.login-area {
  margin: 0 auto;
  max-width: 400px;
  width: 80%;
}
.login-secure {
  color: #adb5bd;
}
.login-secure img {
  margin-right: .3em;
}
.login-container {
  color: #000
}
a {
  cursor: pointer;
  text-decoration: underline;
}
input.error {
  border-color: #dc241f;
}
p.error {
  color: #dc241f;
}
.button-disabled {
  opacity: 0.5;
}
</style>

<script>
import http from '@/util/http'
import emailValidator from 'email-validator'
import { mapMutations } from 'vuex'

export default {
  data () {
    return {
      loading: false,
      state: 'check_user', // check_user, lost_pass, login, register
      showErrors: false,
      successMessage: null,
      email: '',
      lookupStatus: null,
      password: '',
      requestError: ''
    }
  },
  computed: {
    instructions () {
      return {
        check_user: 'Enter your email address to sign in or to create an account.',
        lost_pass: 'Enter your email address to receive instructions.',
        login: 'Welcome Back!',
        register: 'Create Password'
      }[this.state]
    },
    submitText () {
      return {
        check_user: 'Continue',
        lost_pass: 'Request Reset',
        login: 'Login',
        register: 'Create Account'
      }[this.state]
    },
    emailError () {
      if (!emailValidator.validate(this.email)) {
        return 'That email address is invalid.'
      }
      return null
    },
    passwordError () {
      if (!['register'].includes(this.state)) {
        return null
      }
      if (!(this.password && this.password.length >= 8)) {
        return 'Your password must be at least 8 characters long.'
      }
      return null
    },
    canSubmit () {
      if (this.loading) {
        return false
      }
      return true
    }
  },
  watch: {
    email () {
      this.showErrors = false
      if (['login', 'register'].includes(this.state)) {
        this.state = 'check_user'
        this.lookupStatus = null
        this.requestError = null
      }
    },
    state () {
      this.showErrors = false
    }
  },
  methods: {
    ...mapMutations(['setUser', 'setCart', 'setGarage']),
    async submit () {
      this.showErrors = true
      this.requestError = ''
      this.successMessage = null
      if (this.emailError || (this.state === 'register' && this.passwordError)) {
        return
      }
      if (this.loading) {
        return
      }
      this.loading = true
      try {
        const response = await http({
          method: 'POST',
          url: '/backend/api/user.php',
          data: {
            mode: this.state,
            email: this.email,
            ...(['login', 'register'].includes(this.state) ? {
              password: this.password
            } : {})
          }
        })

        if (response.needs_reset + '' === '1') {
          this.state = 'force_reset'
        } else if (response.data || this.state === 'lost_pass') {
          if (this.state === 'check_user') {
            this.lookupStatus = response.data
            this.handleLookupStatus()
          } else if (this.state === 'login') {
            if (response.data) {
              if (!(response.data.user && response.data.user.id)) {
                this.requestError = 'Invalid password.'
              } else {
                // Successful login
                this.setUser(response.data.user)
                this.setCart(response.data.cart)
                this.$store.dispatch('loadGarage')
                this.$emit('close')
              }
            } else {
              this.requestError = 'Could not log in due to an unknown error.'
            }
          } else if (this.state === 'lost_pass') {
            if (response + '' === '1') {
              this.successMessage = 'Instructions have been sent to you.' // This isn't an error but should suffice
            } else {
              this.requestError = 'That account does not exist.'
            }
          } else if (this.state === 'register') {
            if (response.data === 'error_exists') {
              // User already exists, switch to "login" view
              this.lookupStatus = 1
              this.handleLookupStatus()
              await this.$nextTick()
              this.requestError = 'A user with that email address already exists.  Please log in.'
            } else if (response.data) {
              this.setUser(response.data.user)
              this.setCart(response.data.cart)
              this.$store.dispatch('loadGarage')
              this.$emit('close')
              window.location = '/account/info'
            }
          }
        }
        this.loading = false
      } catch (e) {
        this.loading = false
        this.requestError = 'There was an error making this request.'
        throw e
      }
    },
    async handleLookupStatus () {
      if (this.lookupStatus) {
        if (this.lookupStatus.user === 0) {
          this.state = 'register'
        } else if (this.lookupStatus.user === 1) {
          this.state = 'login'
        } else {
          this.state = 'check_user'
        }
      } else {
        this.state = 'check_user'
      }
      await this.$nextTick()
      if (this.$refs.passwordField) {
        this.$refs.passwordField.focus()
      }
    }
  }
}
</script>
