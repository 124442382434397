import Vue from 'vue'
import Vuex from 'vuex'
import { sync } from 'vuex-router-sync'
import router from '../router'
import jQuery from 'jquery'
import http from '@/util/http'
import { fromPairs, omit } from 'lodash'

Vue.use(Vuex)

let rootMenuItem = { children: window.menu && window.menu[0] ? window.menu[0] : [] }

const addParentToMenuItem = (menuItem, parent) => {
  menuItem = {
    ...menuItem,
    parent
  }
  if (menuItem.children) {
    menuItem.children = menuItem.children.map(item => addParentToMenuItem(item, menuItem))
  }
  return menuItem
}

rootMenuItem = addParentToMenuItem(rootMenuItem)

const flatMenuItems = []

const populateFlatMenu = (menuItem) => {
  flatMenuItems.push(menuItem);
  (menuItem.children || []).forEach(item => populateFlatMenu(item))
}
populateFlatMenu(rootMenuItem)

const store = new Vuex.Store({
  state: {
    mobileMenuOpen: false,
    cartMenuOpen: false,
    accountModalOpen: false,
    userLoading: false,
    user: null,
    cartLoading: false,
    cart: null
  },
  getters: {
    rootMenuItem (state) {
      return {
        ...rootMenuItem,
        children: (rootMenuItem.children || []).concat([])
      }
    },
    routeMenuItem (state) {
      let menuItem = rootMenuItem
      if (state.route && state.route.path && state.route.path !== '/') {
        let path = state.route.path
        if (!path.endsWith('/')) {
          path += '/'
        }
        const flatMenuItem = flatMenuItems.find(item => item.cdseo_url === path || item.cdseo_url + '/' === path)
        if (flatMenuItem) {
          menuItem = flatMenuItem
        }
      }
      return {
        ...menuItem,
        children: (menuItem.children || []).concat([])
      }
    }
  },
  mutations: {
    mobileMenu (state, open) {
      state.mobileMenuOpen = open
    },
    cartMenu (state, open) {
      state.cartMenuOpen = open
    },
    accountModal (state, open) {
      state.accountModalOpen = open
    },
    setUser (state, user) {
      state.user = user
    },
    setUserLoading (state, loading) {
      state.userLoading = loading
    },
    setCart (state, cart) {
      state.cart = cart || {}
    },
    setCartLoading (state, loading) {
      state.cartLoading = loading
    }
  },
  actions: {
    async openAccount ({ commit, state }) {
      if (state.user && state.user.id) {
        window.location = '/account'
      } else {
        this.commit('accountModal', true)
      }
    },
    async getSession ({ dispatch }) {
      // Alias
      dispatch('loadUser')
    },
    async loadUser ({ commit, dispatch }) {
      commit('setUserLoading', true)
      try {
        dispatch('loadCart')
        const response = await http({
          method: 'POST',
          url: '/backend/api/user.php',
          data: {
            mode: 'get_session'
          }
        })

        // Set the user to the store
        if (response && response.data) {
          const data = response.data
          commit('setUser', data.user && !Array.isArray(data.user) ? data.user : null)
        }
        commit('setUserLoading', false)
      } catch (e) {
        commit('setUserLoading', false)
        throw e
      }
    },
    async loadCart ({ commit, dispatch }) {
      commit('setCartLoading', true)
      try {
        const response = await http({
          method: 'POST',
          url: '/backend/api/minicart.php',
          data: {
            json_response: 1
          }
        })

        // Set the user to the store
        if (response) {
          commit('setCart', response)
        }
        commit('setCartLoading', false)
      } catch (e) {
        commit('setCartLoading', false)
        throw e
      }
    },
    async addToCart ({ commit }, options) {
      const {
        productid,
        amount = 1,
        event = null,
        before = null,
        after = null
        // productcode = null,
        // name = null,
        // category = null,
        // brand = null,
        // price = null
      } = options

      let {
        product_options: productOptions
      } = options

      if (!productid) {
        return
      }

      const extraOptions = {}

      if (productOptions && Array.isArray(productOptions)) {
        productOptions = fromPairs(
          productOptions.map(({ name, value }) => {
            // If the name already has product_options in it then assume it needs to be added as a
            // root level param to the request instead of under the product_options
            if (name && name.includes('product_options')) {
              extraOptions[name] = value
              return null
            }
            return [name, value]
          }).filter(v => !!v)
        )
      }
      if (typeof before === 'function') {
        await before(options)
      }

      // window.gtag('event', 'add_to_cart', {
      //   items: [{
      //     id: productcode,
      //     name,
      //     category,
      //     brand,
      //     price: price ? (price + '').replace('$', '') : undefined,
      //     quantity: amount
      //   }]
      // })

      const promise = http({
        url: '/cart.php',
        type: 'POST',
        data: {
          json_response: 1,
          ...omit(options, ['event', 'before', 'after']),
          mode: 'add',
          amount,
          product_options: productOptions,
          ...extraOptions
        }
      })

      if (event && event.target) {
        jQuery(event.target).attr('disabled', 'disabled')
      }

      try {
        commit('setCartLoading', true)
        const response = await promise

        if (window._dcq) {
          // console.log('send to drip', getCookie('wcm_cartid'))
          window._dcq.push(['identify', {
            cartid: getCookie('wcm_cartid')
          }])
        }
        commit('setCart', response)
        commit('setCartLoading', false)
        if (typeof after === 'function') {
          await after(options)
        }
        // Show the cart slideout
        commit('cartMenu', true)
        if (event && event.target) {
          jQuery(event.target).removeAttr('disabled')
        }
        return response
      } catch (e) {
        commit('setCartLoading', false)
        if (event && event.target) {
          jQuery(event.target).removeAttr('disabled')
        }
        throw e
      }
    },
    async deleteFromCart ({ commit, state }, { type, id }) {
      if (!id) {
        return false
      }
      commit('setCartLoading', true)
      try {
        const products = (state.cart?.products || [])
        const product = products.find(p => p.cartid + '' === id + '')
        if (products.length <= 1) {
          commit('cartMenu', false)
        }

        // To delete a product
        let params = {
          type: 'GET',
          url: `/cart.php?mode=delete&productindex=${id}&json_response=1`
        }
        if (type === 'giftcert') {
          params = {
            type: 'GET',
            url: `/cart.php?keep_https=1&mode=gc_removecart&gcindex=${id}`
          }
        }
        // TODO: Convert the url into separate parameters
        const response = await http(params)
        if (product) {
          // GA tracking
          if (type === 'product') {
            window.gtag('event', 'remove_from_cart', {
              items: [{
                id: product.productcode,
                name: product.product
              }]
            })
          }
        }
        commit('setCart', response)
        commit('setCartLoading', false)
      } catch (e) {
        commit('setCartLoading', false)
        throw e
      }
    },
    async logout ({ commit }, force) {
      if (!force) {
        await http({
          method: 'POST',
          url: '/backend/api/user.php',
          data: {
            mode: 'logout'
          }
        })
      }

      // Clear out user data
      commit('setUser', null)

      // Clear out cart data
      commit('setCart', null)

      commit('accountModal', false)
    }
  }
})

sync(store, router)
window.store = store

document.body.addEventListener('click', function () {
  store.commit('cartMenu', false)
}, false)

window.addToCart = async (options = {}) => {
  return store.dispatch('addToCart', options)
}

const getCookie = (key) => {
  var result
  // eslint-disable-next-line no-cond-assign
  return (result = new RegExp('(?:^|; )' + encodeURIComponent(key) + '=([^;]*)').exec(document.cookie)) ? (result[1]) : null
}
window.getCookie = getCookie

window.openCart = () => {
  store.commit('cartMenu', true)
}
window.closeCart = () => {
  store.commit('cartMenu', false)
}

// copy title and h1 content into correct places on page automatically
jQuery(document).ajaxSuccess(function (event, xhr, settings) {
  if (xhr && typeof xhr.getResponseHeader === 'function') {
    // Update page title and h1 tag if provided by server
    let pageTitle = xhr.getResponseHeader('x-page-title')
    if (pageTitle) {
      pageTitle = pageTitle ? decodeURIComponent(pageTitle) : pageTitle
      jQuery('head title').text(pageTitle)
    }
    let headerTitle = xhr.getResponseHeader('x-page-h1')
    if (headerTitle) {
      headerTitle = headerTitle ? decodeURIComponent(headerTitle) : headerTitle
      jQuery('#app-h1').text(headerTitle)
    }
    const location = xhr.getResponseHeader('x-location')
    if (location) {
      router.replace({
        path: location
      })
    }
  }
})

export default store
